// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import GoogleMapWithMarker from '@bodhi-project/components/lib/map/GoogleMapWithMarker'

import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/row/style/css'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/col/style/css'

import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'
import Video from '@bodhi-project/components/lib/Video'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import K12SchoolPageWrapper from '../components/wrappers/K12SchoolPageWrapper'
import seoHelper from '../methods/seoHelper'

import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'K12 School Locations | Learning Wings Education System',
  nakedPageSlug: 'about',
  pageAbstract:
    'Learning Wings Education Systems has K1-12 and Early Education schools spread over North India. At our schools the goal is to nurture the dreams of tender hearts. Every child is equipped with the confidence to pursue the dreams and realize them. In this world of turmoil and unrest they learn to pave their own path to success.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <K12SchoolPageWrapper className="page" seoData={seoData} {...props}>
    <h1 class="shadow" data-shadow="K12 School Locations">
      K12 School Locations
    </h1>
    <p>
      Our flagship schools - Cambridge International School - spread across
      North India are showing children the process of realizing their potential.
      Visit the school websites, or call us, or write to us for more information
      –
    </p>
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Jalandhar"
        >
          Jalandhar
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 31.29336, lng: 75.58589, zoom: 12 }}
          marker={{ lat: 31.29336, lng: 75.58589 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge International School For Girls</strong>
          <br />
          Urban Estate, Phase-II,
          <br />
          Jalandhar,
          <br />
          Punjab,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps?q=Cambridge%20International%20School%20For%20Girls,+Urban%20Estate,+Phase-II,+Jalandhar,+Punjab,+India">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:enquiry@cisfgjal.school?subject=Enquiry">
            enquiry@cisfgjal.school
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:0181-4623952">0181-4623952</Link>
          ,&nbsp;
          <Link to="tel:0181-4623953">0181-4623952</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="https://www.cisfgjal.school/">
            https://www.cisfgjal.school/
          </Link>
        </p>
      </div>
    </Division>
    <br />
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 31.32606, lng: 75.57562, zoom: 12 }}
          marker={{ lat: 31.32606, lng: 75.57562 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge International Foundation School</strong>
          <br />
          Urban Estate, Phase-I,
          <br />
          Jalandhar,
          <br />
          Punjab,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps?q=Cambridge%20International%20Foundation%20School,+Urban%20Estate,+Phase-I,+Jalandhar,+Punjab,+India">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:enquiry@cifsjal.school?subject=Enquiry">
            enquiry@cifsjal.school
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:0181-4611888">0181-4611888</Link>
          ,&nbsp;
          <Link to="tel:0181-4622888">0181-4622888</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="https://www.cifsjal.school/">
            https://www.cifsjal.school/
          </Link>
        </p>
      </div>
    </Division>
    <br />
    <br />
    <br />
    {/* ------------------------------------------------------------------- */}
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Nawanshar"
        >
          Nawanshar
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 31.149615, lng: 76.062958, zoom: 12 }}
          marker={{ lat: 31.149615, lng: 76.062958 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge International School</strong>
          <br />
          Banga Nawanshar Road, Kariha,
          <br />
          Nawanshahar,
          <br />
          Punjab,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps?q=Cambridge%20International%20School,+Banga%20Nawanshar%20Road,+Kariha,+Nawanshahar,+Punjab,+India">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:cisn2013@gmail.com?subject=Enquiry">
            cisn2013@gmail.com
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:01823-504555">01823-504555</Link>
          ,&nbsp;
          <Link to="tel:0181-4622888">01823-504556</Link>
          ,&nbsp;
          <Link to="tel:8283820855">8283820855</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="http://www.cisnawanshahr.com/">
            http://www.cisnawanshahr.com/
          </Link>
        </p>
      </div>
    </Division>
    <br />
    <br />
    <br />
    {/* ------------------------------------------------------------------- */}
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Kapurthala"
        >
          Kapurthala
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 31.427896, lng: 75.373355, zoom: 12 }}
          marker={{ lat: 31.427896, lng: 75.373355 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge International School</strong>
          <br />
          Subhanpur Road,
          <br />
          Kapurthala,
          <br />
          Punjab,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps?q=Cambridge%20International%20School,+Subhanpur%20Road,+Kapurthala,+Punjab,+India">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:ciskapurthala@gmail.com?subject=Enquiry">
            ciskapurthala@gmail.com
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:8725088801">8725088801</Link>
          ,&nbsp;
          <Link to="tel:8725088802">8725088802</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="https://www.facebook.com/Cambridge-International-School-Kapurthala-588877601183086/">
            https://www.facebook.com/Cambridge-International-School-Kapurthala-588877601183086/
          </Link>
        </p>
      </div>
    </Division>
    <br />
    <br />
    <br />
    {/* ------------------------------------------------------------------- */}
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Nakodar"
        >
          Nakodar
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 31.107148, lng: 75.469545, zoom: 12 }}
          marker={{ lat: 31.107148, lng: 75.469545 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge International School</strong>
          <br />
          Pandori Khaas Road,
          <br />
          Nakodar,
          <br />
          Punjab,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps?q=Cambridge%20International%20School,+Pandori%20Khaas%20Road,+Nakodar,+Punjab,+India">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:cisnakodar@gmail.com?subject=Enquiry">
            cisnakodar@gmail.com
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:01821-252975">01821-252975</Link>
          ,&nbsp;
          <Link to="tel:01821-253975">01821-253975</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="https://www.cisnakodar.in/">
            https://www.cisnakodar.in/
          </Link>
        </p>
      </div>
    </Division>
    <br />
    <br />
    <br />
    {/* ------------------------------------------------------------------- */}
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Sultanpur Lodhi"
        >
          Sultanpur Lodhi
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 31.225217, lng: 75.194244, zoom: 12 }}
          marker={{ lat: 31.225217, lng: 75.194244 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge International School</strong>
          <br />
          Talwandi Road,
          <br />
          Sultanpur Lodhi,
          <br />
          Punjab,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps?q=Cambridge%20International%20School,+Talwandi%20Road,+Sultanpur%20Lodhi,+Punjab,+India">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:cissultanpurlodhi@gmail.com?subject=Enquiry">
            cissultanpurlodhi@gmail.com
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:01828-224899">01828-224899</Link>
          ,&nbsp;
          <Link to="tel:9815270599">9815270599</Link>
          ,&nbsp;
          <Link to="tel:7087370599">7087370599</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="https://www.facebook.com/cissultanpurlodhi/">
            https://www.facebook.com/cissultanpurlodhi/
          </Link>
        </p>
      </div>
    </Division>
    <br />
    <br />
    <br />
    {/* ------------------------------------------------------------------- */}
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Patti"
        >
          Patti
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 31.27091, lng: 74.823092, zoom: 12 }}
          marker={{ lat: 31.27091, lng: 74.823092 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge International School</strong>
          <br />
          Khemkaran Road,
          <br />
          Patti,
          <br />
          Punjab,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps?q=Cambridge%20International%20School,+Khemkaran%20Road,+Patti,+Punjab,+India">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:cambridgepatti@yahoo.com?subject=Enquiry">
            cambridgepatti@yahoo.com
          </Link>
          ,&nbsp;
          <Link to="mailto:info@cambridgepatti.in?subject=Enquiry">
            info@cambridgepatti.in
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:+91-81024-81024">+91-81024-81024</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="https://www.facebook.com/cambridgeinternationalpatti/">
            https://www.facebook.com/cambridgeinternationalpatti/
          </Link>
        </p>
      </div>
    </Division>
    <br />
    <br />
    <br />
    {/* ------------------------------------------------------------------- */}
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Mohali"
        >
          Mohali
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 30.779609, lng: 76.547252, zoom: 12 }}
          marker={{ lat: 30.779609, lng: 76.547252 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge International School</strong>
          <br />
          NH-95, Gharuan, Chandigarh Ludhiana Highway,
          <br />
          Mohali,
          <br />
          Punjab,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps?q=Cambridge%20International%20School,+NH%2095,+Gharuan,%20Chandigarh%20Ludhiana%20Highway,+Mohali,+Punjab,+India">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:cambridgemohali@gmail.com?subject=Enquiry">
            cambridgemohali@gmail.com
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:+91-9872582135">+91-9872582135</Link>
          ,&nbsp;
          <Link to="tel:+91-9872682135">+91-9872682135</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="http://www.cambridgemohali.com/">
            http://www.cambridgemohali.com/
          </Link>
        </p>
      </div>
    </Division>
    <br />
    <br />
    <br />
    {/* ------------------------------------------------------------------- */}
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Garhshankar"
        >
          Garhshankar
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 31.215082, lng: 76.144335, zoom: 12 }}
          marker={{ lat: 31.215082, lng: 76.144335 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge Sparkling Stars (Play School)</strong>
          <br />
          Banga Chowk,
          <br />
          Garhshankar,
          <br />
          Punjab,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps?q=Cambridge%20Sparkling%20Stars%20(Play%20School),+Banga%20Chowk,+Garhshankar,+Punjab,+India">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:cambridgesparklingstars@gmail.com?subject=Enquiry">
            cambridgesparklingstars@gmail.com
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:01884-283400">01884-283400</Link>
          ,&nbsp;
          <Link to="tel:9464931155">9464931155</Link>
          ,&nbsp;
          <Link to="tel:9464921155">9464921155</Link>
        </p>
      </div>
    </Division>
    <br />
    <br />
    <br />
    {/* ------------------------------------------------------------------- */}
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Delhi"
        >
          Delhi
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 28.652658, lng: 77.295111, zoom: 12 }}
          marker={{ lat: 28.652658, lng: 77.295111 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge Sparkling Stars and Development Centre</strong>
          <br />
          No. 1, AGCR Enclave,
          <br />
          Adjoining Central School,
          <br />
          Delhi - 110092,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps/place/Cambridge+Sparkling+Stars/@28.6526561,77.2928494,17z/data=!3m1!4b1!4m5!3m4!1s0x390cfb5d9de67f41:0x931b1e83ec3f3ef9!8m2!3d28.6526514!4d77.2950381?hl=en-US">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:cssdelhi@hotmail.com?subject=Enquiry">
            cssdelhi@hotmail.com
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:+91-9717187303">+91-9717187303</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="https://www.cambridgesparklingstars.com/">
            https://www.cambridgesparklingstars.com/
          </Link>
        </p>
      </div>
    </Division>
    <br />
    <br />
    <br />
    {/* ------------------------------------------------------------------- */}
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Gurdaspur"
        >
          Gurdaspur
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 32.027467, lng: 75.35175, zoom: 12 }}
          marker={{ lat: 32.027467, lng: 75.35175 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge International School</strong>
          <br />
          Kalanaur Road,
          <br />
          Gurdaspur,
          <br />
          Punjab,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps?q=Cambridge%20International%20School,+Dera%20Baba%20Nanak%20Road,+Khokhar,+Gurdaspur,+Punjab,+India">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:cambridge.gsp@gmail.com?subject=Enquiry">
            cambridge.gsp@gmail.com
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:7451800096">7451800096</Link>
          ,&nbsp;
          <Link to="tel:7452800096">7452800096</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="https://www.facebook.com/Cambridge-International-School-Gurdaspur-1065401170230498/">
            https://www.facebook.com/Cambridge-International-School-Gurdaspur-1065401170230498/
          </Link>
        </p>
      </div>
    </Division>
    <br />
    <br />
    <br />
    {/* ------------------------------------------------------------------- */}
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Jammu"
        >
          Jammu
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 32.801095, lng: 74.78882, zoom: 12 }}
          marker={{ lat: 32.801095, lng: 74.78882 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge International School</strong>
          <br />
          Purkhoo Camp, Domana,
          <br />
          Jammu - 181206,
          <br />
          Jammu & Kashmir,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps/place/Cambridge+International+School/@32.8008195,74.7865963,17z/data=!3m1!4b1!4m5!3m4!1s0x391e87041a838b35:0x5b669c34b46bfe85!8m2!3d32.800815!4d74.788785?hl=en-US">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:cisjammu2018@gmail.com?subject=Enquiry">
            cisjammu2018@gmail.com
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:0191-2604700">0191-2604700</Link>
          ,&nbsp;
          <Link to="tel:0191-2604800">0191-2604800</Link>
          ,&nbsp;
          <Link to="tel:9419604700">9419604700</Link>
          ,&nbsp;
          <Link to="tel:9419704800">9419704800</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="https://www.cambridgejammu.com/">
            https://www.cambridgejammu.com/
          </Link>
        </p>
      </div>
    </Division>
    <br />
    <br />
    <br />
    {/* ------------------------------------------------------------------- */}
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Pathankot"
        >
          Pathankot
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 32.385803, lng: 75.697942, zoom: 12 }}
          marker={{ lat: 32.385803, lng: 75.697942 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge International School</strong>
          <br />
          Shahpur Kandi,
          <br />
          Pathankot - 145029,
          <br />
          Punjab,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps/place/Cambridge+International+School,+Pathankot/@32.3856117,75.696185,17z/data=!3m1!4b1!4m5!3m4!1s0x391c7d46bfa3888b:0xdcd6e012ab77cc8d!8m2!3d32.3856072!4d75.6983737?hl=en-US">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:cispathankot2018@gmail.com?subject=Enquiry">
            cispathankot2018@gmail.com
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:+91-9646028160">+91-9646028160</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="https://www.cambridgepathankot.com/">
            https://www.cambridgepathankot.com/
          </Link>
        </p>
      </div>
    </Division>
    <br />
    <br />
    <br />
    {/* ------------------------------------------------------------------- */}
    <Division>
      <div>
        <h2
          style={{ textAlign: 'right' }}
          className="shadow"
          data-shadow="Mandi"
        >
          Mandi
        </h2>
      </div>
      <div>&nbsp;</div>
    </Division>
    <Division>
      <div>
        <GoogleMapWithMarker
          map={{ lat: 31.617113, lng: 76.938468, zoom: 12 }}
          marker={{ lat: 31.617113, lng: 76.938468 }}
        />
      </div>
      <div>
        <p className="copy">
          <strong>Cambridge International School</strong>
          <br />
          LunaPani, Balh,
          <br />
          Mandi - 175021,
          <br />
          Himachal Pradesh,
          <br />
          India
          <br />
          <br />
          Location:&nbsp;
          <Link to="https://www.google.com/maps/place/Cambridge+International+School+Mandi/@31.6166644,76.9348668,17z/data=!3m1!4b1!4m5!3m4!1s0x39051decc66fe74d:0x6b70d585e18b74ee!8m2!3d31.6166599!4d76.9370555?hl=en-US">
            Google Pin
          </Link>
          <br />
          Email:&nbsp;
          <Link to="mailto:cambridgemandi@gmail.com?subject=Enquiry">
            cambridgemandi@gmail.com
          </Link>
          <br />
          Phone:&nbsp;
          <Link to="tel:01905-243366">01905-243366</Link>
          ,&nbsp;
          <Link to="tel:+91-8580579409">+91-8580579409</Link>
          <br />
          <br />
          Website:&nbsp;
          <Link to="https://www.cambridgemandi.com/">
            https://www.cambridgemandi.com/
          </Link>
        </p>
      </div>
    </Division>
  </K12SchoolPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
